
import { Component, Mixins } from 'vue-property-decorator';
import ConfigurationStatus from '@/components/data-operations/common/configuration/ConfigurationStatus.vue';

import HeaderInfosMixin from '../header-infos';

import { xmlConversionConf } from '@/store/modules/easy-firestore/xml-conversion-conf';
import { XML_CONVERSION_CONFIGURATIONS_ITEM } from '@/constants/router/routes-names';
import {
	ACTIONS,
	ENVIRONMENT,
	GCP_PROJECT_ID,
	GCS_BUCKET,
	GCS_WORKING_DIRECTORY,
	ID,
} from '@/constants/data-operations/listing/header-items';
import ConfigurationCollectionMixin from '@/mixins/data-operations/collection/configuration-collection-mixin';

@Component({
	components: { ConfigurationStatus },
})
export default class TailerContextConfListingView extends Mixins(HeaderInfosMixin, ConfigurationCollectionMixin) {
	moduleName: string = xmlConversionConf.moduleName;
	overriddenColumns: string[] = ['id'];

	get routeName() {
		return XML_CONVERSION_CONFIGURATIONS_ITEM;
	}

	get headers() {
		return [ENVIRONMENT, ID, GCP_PROJECT_ID, GCS_BUCKET, GCS_WORKING_DIRECTORY, ACTIONS];
	}
}
